<template>
  <svg
    version="1.1"
    class="logo"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 219 67"
    style="enable-background:new 0 0 219 67;"
    xml:space="preserve"
  >
    <path
      fill="currentColor"
      class="logo__text"
      d="M216.5 51.4c-2.2 0-3.3-1.2-3.3-3.4V32.1h5.4v-2.6h-5.4v-6.3l-2.8 1.2v5.1h-4.7v2.6h4.7V47c0 3.2-.3 7.1 5.7 7.1 1 0 1.9-.2 2.9-.4v-2.5c-.8.1-1.6.2-2.5.2z"
    />
    <path fill="currentColor" class="logo__text" d="M201.2 19h2.8v34.3h-2.8z" />
    <path
      fill="currentColor"
      class="logo__text"
      d="M197.2 36.6c.1-5.5-3.2-7.9-8.4-7.9-4.5 0-8.2 1.9-8.9 6.7h3c.4-3.2 2.8-4.1 5.8-4.1 3.4 0 5.3 1.2 5.6 5.1v2.2c-3.5.2-7.6-.3-10.7 1.1-2.9 1.1-4.8 3.8-4.8 6.9 0 4.7 3.5 7.4 7.9 7.4 3.2 0 6.4-1.9 7.4-3.9l.1-.3h.1l.1 3.5h2.9c-.1-1.6-.3-3.5-.2-5.4V36.6zm-10 14.8c-2.9 0-5.3-1.5-5.3-4.7 0-6 8.4-5.2 12.4-5.4.3 5-.9 10.1-7.1 10.1zM168 28.7c-3.3-.1-6.4 1.6-8.1 4.3l-.1-.1V19H157v28.9c0 1.8-.1 3.7-.2 5.4h2.9l.1-3.5.1-.2c1.8 2.8 4.9 4.5 8.3 4.4 6.9 0 9.6-6.7 9.6-12.7 0-6.2-2.7-12.6-9.8-12.6zm-.1 22.7c-6.2 0-8.1-5.1-8.1-10.5 0-4.8 2.1-9.5 7.7-9.5 5.4 0 7.2 5.1 7.2 9.7 0 4.4-1.4 10.3-6.8 10.3zM143.1 28.7c-7.6 0-11.1 6.4-11.1 12.6S135.5 54 143.1 54s11.1-6.4 11.1-12.6-3.5-12.7-11.1-12.7zm0 22.7c-5.7 0-8-5.6-8-10s2.3-10 8-10 8 5.6 8 10-2.3 10-8 10z"
    />
    <path
      fill="currentColor"
      class="logo__text"
      d="M133.2 19h-3.8l-15.3 15.1L114 19h-3v34.3h3l.1-17.9 16.4 17.9h4.2l-17.2-18.8"
    />
    <path fill="currentColor" class="logo__mark" d="M87 0H54v32H28V0H0v67h28V38.6L54 67h33L55 32" />
  </svg>
</template>

<script>
export default {
  name: 'Kobalt'
}
</script>