import axios from 'axios';

export default {
  getCrimeCategories: () => {
    return axios.get('https://data.police.uk/api/crime-categories').then(response => response.data)
  },
  getCrimeStatsLastUpdatedDate: () => {
    return axios.get('https://data.police.uk/api/crime-last-updated').then(response => response.data)
  },
  getCrimesAtLocation({ latitude, longitude }) {
    // return axios.get(`https://data.police.uk/api/crimes-street/all-crime?lat=${lat}&lng=${lng}&date=${date}`).then(response => response.data)
    return axios.get(`https://data.police.uk/api/crimes-street/all-crime?lat=${latitude}&lng=${longitude}`).then(response => response.data)
  },
  getCrimesInLondon: () => {
    // TODO doesnt work, server returns error, assume its because area is too large
    // TODO find another way to show this? download data from police and display using own server?
    const londonPerimeter = {
      topLeft: {
        lat: 51.699817, lng: -0.519061
      },
      bottomLeft: {
        lat: 51.283988, lng: -0.509065
      },
      bottomRight: {
        lat: 51.288992, lng: 0.352113
      },
      topRight: {
        lat: 51.684823, lng: 0.325687
      }
    };

    let polyCoordinates = londonPerimeter.topLeft.lat + ',' + londonPerimeter.topLeft.lng;
    polyCoordinates += ':' + londonPerimeter.bottomLeft.lat + ',' + londonPerimeter.bottomLeft.lng
    polyCoordinates += ':' + londonPerimeter.bottomRight.lat + ',' + londonPerimeter.bottomRight.lng
    polyCoordinates += ':' + londonPerimeter.topRight.lat + ',' + londonPerimeter.topRight.lng

    return axios.get(`https://data.police.uk/api/crimes-street/all-crime?poly=${polyCoordinates}`)
  }
}