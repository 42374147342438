<template>
  <tr class="email-summary">
    <td>
      <i class="far fa-square"></i>
      <i class="far fa-star"></i>
      <i class="fas fa-chevron-right"></i>
    </td>
    <td class="email-summary__from-address">
      {{emailFromAddress}}
    </td>
    <td class="email-summary__subject-and-snippet">
      <span class="email-summary__subject">{{emailSubject}}</span> - <span>{{email.snippet}}</span>
    </td>
    <td class="email-summary__datetime">
      {{emailDateTime}}
    </td>
  </tr>
</template>

<script>

import '@fortawesome/fontawesome-free/css/all.css';

function getHeader(email, type) {
  return email.payload.headers.find(header => header.name === type).value;
}

export default {
  name: 'EmailSummary',
  props: [ 'email' ],
  computed: {
    emailFromAddress() {
      return getHeader(this.email, 'From')
    },
    emailSubject() {
      return getHeader(this.email, 'Subject')
    },
    emailDateTime() {
      const dateObject = new Date(this.email.internalDate);
      return dateObject.toDateString();
    }
  },
}
</script>

<style>
.email-summary {
  display: flex;
  flex-direction: row;

  padding: 10px;
  border-bottom: 1px solid rgb(225, 225, 225);
}

.email-summary:hover {
  box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
  cursor: pointer;
}

.email-summary__from-address {
  flex: 1;
  font-weight: bold;
}

.email-summary__subject-and-snippet{
  flex: 6;
}

.email-summary__subject {
  font-weight: bold;
}

.email-summary__datetime {
  flex: 1;
  font-weight: bold;
}

.email-summary .far, .email-summary .fas {
  color: rgb(225, 225, 225);
  margin-right: 10px;
}
</style>
