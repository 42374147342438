<template>
  <button
    class="ripple"
    v-bind:class="{ success: isSuccess && !isFailure, failure: !isSuccess && isFailure }"
    v-on:click="buttonClick()"
  >{{textToDisplay}}</button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
    onClick: Function
  },
  data: function() {
    return {
      textToDisplay: this.text,
      isSuccess: false,
      isFailure: false
    };
  },
  methods: {
    buttonClick: function() {
      this.onClick()
        .then(() => {
          this.textToDisplay = "Success!";
          this.isSuccess = true;
        })
        .catch(() => {
          this.textToDisplay = "Failure...";
          this.isFailure = true;
        });
    }
  }
};
</script>

<style>
button {
  cursor: pointer;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: 5px solid;
  border-color: #278ea5;
  background-color: #071e3d;
  color: white;
  font-size: 30px;
  font-weight: bold;
}

.success {
  animation: success 1s forwards;
}

.failure {
  animation: failure 1s forwards;
}

@keyframes success {
  to {
    border-color: #33ff00;
  }
}

@keyframes failure {
  to {
    border-color: red;
  }
}

button:focus {
  outline: none;
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}

.ripple:hover {
  background: #1f4287 radial-gradient(circle, transparent 1%, #1f4287 1%)
    center/15000%;
}

.ripple:active {
  background-color: #2957b3;
  background-size: 100%;
  transition: background 0s;
}
</style>