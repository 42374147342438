<template>
  <div id="tutorial">
    <div id="particles-js"></div>

    <div id="foreground">
      <!-- <LearnettoLogo class="learnetto"/> -->

      <Button text="Test" :onClick="checkAuthentication"/>

      <a id="googleSignIn" href>
        <img alt="google sign in" src="../../../assets/google-sign-in.png">
      </a>
    </div>
  </div>
</template>

<script>
import Button from "./components/Button";
// import LearnettoLogo from "../../../components/LearnettoLogo";
import "particles.js";
import particlesJsConfig from "./particlesjs-config.json";

export default {
  name: "tutorial",
  components: {
    Button,
    // LearnettoLogo
  },
  methods: {
    checkAuthentication() {
      // TODO
      // return Promise.resolve();
      return Promise.reject();
    }
  },
  mounted: function() {
    window.particlesJS("particles-js", particlesJsConfig);
  }
};
</script>

<style scoped>
#tutorial {
  height: 100vh;
  font: menu;
  background-color: #071e3d;
  margin: 0;
}

#googleSignIn {
  margin-top: 50px;
}

.learnetto {
  position: absolute;
  top: 15px;
  right: 10px;
  color: white;
  font-size: 16px;
  background-color: #071e3d;
  padding: 20px;
  border-radius: 10px;
}

#particles-js {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 5px;
}

#particles-js canvas {
  z-index: -1;
}

#foreground {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

#foreground * {
  z-index: 1;
}

</style>


