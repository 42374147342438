<template>
  <div id="index">
    <div class="h-screen background-color flex flex-col">
      <div class="my-8 mx-auto sm:m-auto flex flex-1 flex-col sm:flex-row">
        <ProfileImage />

        <div
          class="flex flex-col justify-center mt-4 sm:mt-0 sm:ml-8 text-center sm:text-left"
        >
          <!-- <Uncover color="#3e4044" delay="1.5s">
            <div class="text-4xl text-white">Hi, I'm Tolga</div>
          </Uncover>
          <Uncover color="#3e4044" delay="2.5s">
            <div class="text-2xl text-gray-300">Web Developer</div>
          </Uncover> -->

          <Uncover color="#fff" delay="2.5s">
            <div class="text-4xl text-white">Tolga Cura</div>
          </Uncover>
          <Uncover color="rgb(251, 191, 36)" delay="3.5s">
            <div class="text-2xl text-yellow-400">Web Developer</div>
          </Uncover>
        </div>
      </div>

      <div
        class="animate__animated animate__fadeIn animate__delay-4s text-center mb-2 sm:mb-4 cursor-pointer"
        v-on:click="goToExperience"
      >
        <i
          class="text-white fas fa-chevron-down fa-2x animate__bounce animate__infinite animate__delay-5s"
          :class="{ animate__animated: animateScrollIndicator }"
        ></i>
      </div>
    </div>

    <div class="p-4 max-w-3xl m-auto" ref="experience">
      <div class="text-4xl text-center text-gray mt-4">Experience</div>

      <v-timeline :dense="breakpoints.isXS()">
        <v-timeline-item small>
          <template v-slot:opposite>
            <div>
              <div class="font-bold text-xl">Developer Team Lead</div>
              <div>Apr 2019 - Present</div>
            </div>
          </template>
          <v-card id="jrni" class="work-item">
            <a class="card-link" target="_blank" href="https://www.jrni.com/">
              <v-img>
                <Jrni />
              </v-img>
            </a>
          </v-card>
        </v-timeline-item>
        <v-timeline-item hide-dot v-if="breakpoints.isXS()">
          <div class="text-left">
            <div class="font-bold text-xl">Developer Team Lead</div>
            <div>Apr 2019 - Present</div>
          </div>
        </v-timeline-item>

        <v-timeline-item left small class="xs:text-left sm:text-right">
          <div>
            <div class="font-bold text-xl">Senior Developer</div>
            <div>Jan 2018 - Apr 2019</div>
          </div>
        </v-timeline-item>
        <v-timeline-item left small class="xs:text-left sm:text-right">
          <div>
            <div class="font-bold text-xl">Front-end Developer</div>
            <div>Jul 2016 - Jan 2018</div>
          </div>
        </v-timeline-item>
      </v-timeline>

      <v-timeline :dense="breakpoints.isXS()" :reverse="!breakpoints.isXS()">
        <v-timeline-item small>
          <template v-slot:opposite>
            <div>
              <div class="font-bold text-xl">Software Engineer</div>
              <div>Jul 2013 - Jul 2016</div>
            </div>
          </template>
          <v-card id="kobalt" class="work-item">
            <a
              class="card-link"
              target="_blank"
              href="https://www.kobaltmusic.com/"
            >
              <v-img>
                <Kobalt />
              </v-img>
            </a>
          </v-card>
        </v-timeline-item>
        <v-timeline-item hide-dot v-if="breakpoints.isXS()">
          <div class="text-left">
            <div class="font-bold text-xl">Software Engineer</div>
            <div>Jul 2013 - Jul 2016</div>
          </div>
        </v-timeline-item>

        <v-timeline-item small>
          <template v-slot:opposite>
            <div>
              <div class="font-bold text-xl">Mathematics Teacher</div>
              <div>Feb 2013 - Jul 2013</div>
            </div>
          </template>
          <v-card id="excel-tutors" class="work-item">
            <a
              class="card-link"
              target="_blank"
              href="https://www.exceltutors.org.uk/"
            >
              <v-img src="../assets/excel_tutors_logo.png" />
            </a>
          </v-card>
        </v-timeline-item>
        <v-timeline-item hide-dot v-if="breakpoints.isXS()">
          <div class="text-left">
            <div class="font-bold text-xl">Mathematics Teacher</div>
            <div>Feb 2013 - Jul 2013</div>
          </div>
        </v-timeline-item>

        <v-timeline-item small>
          <template v-slot:opposite>
            <div>
              <div class="font-bold text-xl">SEO Consultant</div>
              <div>Oct 2012 - Apr 2013</div>
            </div>
          </template>
          <v-card id="or-society" class="work-item">
            <a
              class="card-link"
              target="_blank"
              href="https://www.theorsociety.com/"
            >
              <v-img>
                <TheORSociety />
              </v-img>
            </a>
          </v-card>
        </v-timeline-item>
        <v-timeline-item hide-dot v-if="breakpoints.isXS()">
          <div class="text-left">
            <div class="font-bold text-xl">SEO Consultant</div>
            <div>Oct 2012 - Apr 2013</div>
          </div>
        </v-timeline-item>
      </v-timeline>
    </div>

    <div class="bg-gray-800 p-12 sm:p-16">
      <div class="text-center text-white text-xl">mrtolgacura@gmail.com</div>
    </div>
  </div>
</template>

<script>
import Jrni from "../components/Jrni";
import Kobalt from "../components/Kobalt";
import TheORSociety from "../components/TheORSociety";
import ProfileImage from "../components/ProfileImage";
import Uncover from "../components/Uncover";
import breakpoints from "../utils/breakpoints";

export default {
  name: "Website",
  components: {
    Jrni,
    Kobalt,
    TheORSociety,
    ProfileImage,
    Uncover,
  },
  data: () => ({
    animateScrollIndicator: true,
    breakpoints,
  }),
  mounted() {
    document.addEventListener("scroll", this.resetScrollAnimation);
    window.addEventListener('resize', this.resetBreakpoints);
  },
  methods: {
    resetBreakpoints() {
      this.breakpoints = Object.assign({}, breakpoints)
    },
    resetScrollAnimation() {
      this.animateScrollIndicator = false;
      document.removeEventListener("scroll", this.resetScrollAnimation);
    },
    goToExperience() {
      this.$refs["experience"].scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>


<style scoped>
.work-item {
  max-width: 500px;
  padding: 30px;
}

/* .v-timeline:before {
  top: 100px;
} */

@media (max-width: 640px) {
  .work-item {
    padding: 8px;
  }

  /* .v-timeline:before {
    top: 60px;
  } */
}

#jrni svg {
  color: #262265;
}

#kobalt svg {
  color: #ff3a1e;
}

#work-list {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}

.background-color {
  background-color: #657694;
}
</style>