<template>
  <div id="mapContainer">
    <div id="map"></div>
    <v-btn id="geolocation" absolute dark fab top right color="blue" @click="loadGeolocation">
      <v-icon>my_location</v-icon>
    </v-btn>
  </div>
</template>
<script>
// import MapProvider from "../services/maps/here_maps";
import MapProvider from "../services/maps/google_maps";

import Utils from "../services/utils";

export default {
  props: {
    getCurrentLocationCallback: Function,
    dataPoints: Array
  },
  computed: {
    currentLocation() {
      return this.currentGeolocation;
    }
  },
  data() {
    return {
      currentGeolocation: null
    };
  },
  watch: {
    dataPoints: function(newVal) {
      const mapDataPoints = MapProvider.parseDataPoints(newVal);
      MapProvider.loadVisualLayer(mapDataPoints);
    }
  },
  methods: {
    loadGeolocation: function() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(position => {
            this.currentGeolocation = position;

            const { latitude, longitude } = position.coords;
            MapProvider.setCenter({ latitude, longitude });
            MapProvider.setZoom(15);

            this.getCurrentLocationCallback(position);
            resolve(position);
          });
        } else {
          reject();
        }
      });
    }
  },
  mounted() {
    const stylesheetsToLoad = MapProvider.getStylesheetsToLoad();
    Utils.loadStylesheets(stylesheetsToLoad);

    const scripts = MapProvider.getScriptsToLoad();
    Utils.loadScripts(scripts).then(() => {
      MapProvider.generateMap({
        zoom: 11,
        latitude: 51.5074,
        longitude: -0.1278
      });
    });
  }
};
</script>
<style scoped>
#map,
#mapContainer {
  height: 100%;
  width: 100%;
}

#geolocation {
  top: 20px;
  right: 20px;
}
</style>




