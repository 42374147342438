/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
export default class Maps {
  parseDataPoints(dataPoints) {
    console.trace(`function has not been impleneted`);
  }
  loadVisualLayer(parsedDataPoints) {
    console.trace(`function has not been impleneted`);
  }
  setCenter({ latitude, longitude }) {
    console.trace(`function has not been impleneted`);
  }
  setZoom(zoom) {
    console.trace(`function has not been impleneted`);
  }
  getScriptsToLoad() {
    console.trace(`function has not been impleneted`);
  }
  getStylesheetsToLoad() {
    console.trace(`function has not been impleneted`);
  }
  generateMap({ latitude, longitude, zoom}) {
    console.trace(`function has not been impleneted`);
  }
}