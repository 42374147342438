export default {
  loadScript: function (uri) {
    return new Promise(resolve => {
      const tag = document.createElement("script");
      tag.src = uri;
      tag.async = true;
      tag.onload = () => {
        resolve();
      };

      document.head.appendChild(tag);
    });
  },
  loadScripts(urls, index = 0) {
    return this.loadScript(urls[index]).then(() => {
      const newIndex = index + 1;
      if (urls[newIndex]) {
        return this.loadScripts(urls, newIndex);
      } else {
        Promise.resolve();
      }
    })
  },
  loadStylesheet(url) {
    const hereUIStyling = document.createElement("link");
    hereUIStyling.rel = "stylesheet";
    hereUIStyling.type = "text/css";
    hereUIStyling.href = url;
    document.head.appendChild(hereUIStyling);
  },
  loadStylesheets(urls) {
    urls.forEach((url) => {
      this.loadStylesheet(url)
    });
  }
}