<template>
  <svg
    id="HOME_-_desktop"
    data-name="HOME - desktop"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 219 67"
  >
    <defs>
      <!-- <style>
        .cls-1 {
          fill: #d92632;
        }
        .cls-2 {
          fill: #231f20;
        }
      </style> -->
    </defs>
    <title>or-logo</title>
    <path
      class="cls-1"
      fill="#d92632"
      d="M97.83,111h71V40h-71v71Zm20.54-20h-0.06c-7.35,0-13.09-5.48-13.09-12.13,0-9.75,8.35-18.71,17.89-18.71,8,0,13.56,6,13.56,12.25,0,9.14-8.45,18.59-18.29,18.59m15.7,0,5.41-30.87h10.4L145,91H134.08Zm27.24-23.93a6.37,6.37,0,0,1-7.67,5l-0.11,0c-3.12-.64-4.77-3.56-4.08-6.89a6.46,6.46,0,0,1,7.46-5.08,5.59,5.59,0,0,1,4.41,7"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M185,47.48a1.12,1.12,0,0,1-.4,0,1.14,1.14,0,0,1,0,.41v9.79a0.63,0.63,0,1,1-1.26,0V47.83a1.15,1.15,0,0,1,0-.41,1.11,1.11,0,0,1-.4,0h-2.62a0.63,0.63,0,1,1,0-1.26h7.28a0.63,0.63,0,1,1,0,1.26H185Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M197.08,51.62a1.09,1.09,0,0,1,.4,0,1.14,1.14,0,0,1,0-.41V46.88a0.63,0.63,0,1,1,1.26,0V57.62a0.63,0.63,0,1,1-1.26,0V53.24a1.14,1.14,0,0,1,0-.41,1.11,1.11,0,0,1-.4,0h-5.23a1.13,1.13,0,0,1-.41,0,1.1,1.1,0,0,1,0,.41v4.37a0.63,0.63,0,1,1-1.26,0V46.88a0.63,0.63,0,1,1,1.26,0v4.39a1.1,1.1,0,0,1,0,.41,1.1,1.1,0,0,1,.41,0h5.23Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M202.9,51.23a1.12,1.12,0,0,1,0,.4,1.12,1.12,0,0,1,.4,0h5a0.63,0.63,0,1,1,0,1.26h-5a1.1,1.1,0,0,1-.4,0,1.12,1.12,0,0,1,0,.4v3.45a1.12,1.12,0,0,1,0,.4,1.11,1.11,0,0,1,.4,0h6.17a0.63,0.63,0,1,1,0,1.26h-7a0.63,0.63,0,0,1-.78-0.63V46.84a0.63,0.63,0,0,1,.78-0.63h7a0.63,0.63,0,1,1,0,1.26h-6.21a1.14,1.14,0,0,1-.4,0,1.12,1.12,0,0,1,0,.4v3.4Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M184.62,74.1c-2.87,0-4.58-2-4.58-5.41V66.8c0-3.38,1.71-5.4,4.58-5.4s4.56,2,4.56,5.4v1.9c0,3.38-1.71,5.41-4.56,5.41m0-10.81c-2.22,0-2.68,1.91-2.68,3.51v1.9c0,1.6.47,3.51,2.68,3.51s2.66-1.91,2.66-3.51V66.8c0-1.6-.46-3.51-2.66-3.51"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M191.82,74.1a0.93,0.93,0,0,1-.95-1V62.35A0.93,0.93,0,0,1,192,61.4h4c2.67,0,4.14,1.3,4.14,3.67,0,1.67-.72,3.67-4.14,3.67h-3.2v4.38a1,1,0,0,1-.95,1m0.95-7.26h3.12c2,0,2.32-.83,2.32-1.73s-0.26-1.82-2.27-1.82h-3.17v3.55"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M202.67,74.1a0.93,0.93,0,0,1-1.1-.95V62.35a0.93,0.93,0,0,1,1.1-.95h7a0.95,0.95,0,1,1,0,1.9h-6.25v3.48h5.06a0.95,0.95,0,1,1,0,1.9h-5.06V72.2h6.21a1,1,0,1,1,0,1.9h-7Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M220.41,74.12a1,1,0,0,1-.95-0.66l-2.34-4.72H214v4.38a0.95,0.95,0,1,1-1.9,0V62.35a0.93,0.93,0,0,1,1.09-.95h4.21c2.41,0,3.91,1.43,3.91,3.72A3.22,3.22,0,0,1,219,68.45l0.12,0.24L221,72.36l0.11,0.24a1.29,1.29,0,0,1,.2.66,0.9,0.9,0,0,1-.93.85M214,66.84h3.39c1.39,0,2-.55,2-1.74s-0.69-1.81-2.11-1.81H214v3.55Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M231.69,74.12a1,1,0,0,1-.9-0.63l-1-2.45H225l-0.91,2.42a0.94,0.94,0,0,1-1.85-.29,1,1,0,0,1,.07-0.38l4-10.3c0.3-.78.54-1.29,1.07-1.29a1,1,0,0,1,.91.83l0.05,0.14c0,0.11.08,0.22,0.12,0.32l4.14,10.28a0.83,0.83,0,0,1,.09.38,1,1,0,0,1-.93,1m-4.36-9.41c-0.12.37-.23,0.69-0.45,1.26l-1,2.66-0.19.51h3.37L227.8,66c-0.23-.59-0.35-0.91-0.46-1.25"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M235.91,74.1a0.93,0.93,0,0,1-.95-1V63.29h-2.67a0.95,0.95,0,1,1,0-1.9h7.28a0.95,0.95,0,1,1,0,1.9h-2.71v9.83a0.93,0.93,0,0,1-.95,1"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M242.78,74.1a0.93,0.93,0,0,1-.95-1V62.38a1,1,0,1,1,1.9,0V73.12a0.93,0.93,0,0,1-1,1"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M250,74.1c-2.87,0-4.58-2-4.58-5.41V66.8c0-3.38,1.71-5.4,4.58-5.4s4.56,2,4.56,5.4v1.9c0,3.38-1.71,5.41-4.56,5.41m0-10.81c-2.21,0-2.68,1.91-2.68,3.51v1.9c0,1.6.47,3.51,2.68,3.51s2.66-1.91,2.66-3.51V66.8c0-1.6-.46-3.51-2.66-3.51"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M264.46,74.12a1.1,1.1,0,0,1-1-.68l-4.58-6.87c-0.36-.55-0.58-0.89-0.79-1.24,0,0.42,0,.81,0,1.47v6.32a0.95,0.95,0,1,1-1.9,0V62.33a0.94,0.94,0,0,1,.93-0.95,1.1,1.1,0,0,1,1,.68l4.58,6.9c0.37,0.56.58,0.89,0.79,1.23,0-.39,0-0.78,0-1.46V62.38a0.95,0.95,0,1,1,1.9,0V73.17a0.94,0.94,0,0,1-.93.95"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M276.19,74.12a1,1,0,0,1-.91-0.63l-1-2.45h-4.82l-0.91,2.42a0.94,0.94,0,0,1-.89.66,1,1,0,0,1-.95-0.95,1.06,1.06,0,0,1,.07-0.38l4-10.3c0.31-.78.54-1.29,1.07-1.29a1,1,0,0,1,.91.83l0,0.12c0,0.12.08,0.23,0.13,0.34L277,72.78a0.82,0.82,0,0,1,.09.38,1,1,0,0,1-.93,1m-4.36-9.41c-0.12.37-.23,0.69-0.45,1.26l-1,2.66c-0.09.24-.14,0.38-0.2,0.52h3.37L272.29,66c-0.23-.59-0.35-0.91-0.46-1.25"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M279.53,74.1a0.93,0.93,0,0,1-1.1-.95V62.38a0.95,0.95,0,1,1,1.9,0V72.2h5a0.94,0.94,0,0,1,1,.95,1,1,0,0,1-1,.95h-5.77Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M189.2,89.62a1,1,0,0,1-.95-0.66l-2.34-4.72h-3.13v4.38a0.95,0.95,0,1,1-1.9,0V77.85A0.93,0.93,0,0,1,182,76.9h4.21c2.41,0,3.91,1.43,3.91,3.72a3.21,3.21,0,0,1-2.26,3.33L188,84.19l1.86,3.67,0.11,0.24a1.28,1.28,0,0,1,.2.66,0.9,0.9,0,0,1-.93.85m-6.42-7.28h3.39c1.39,0,2-.55,2-1.74s-0.69-1.81-2.11-1.81h-3.31v3.55"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M192.81,89.61a0.93,0.93,0,0,1-1.09-.95V77.85a0.93,0.93,0,0,1,1.09-.95h7a0.95,0.95,0,1,1,0,1.9h-6.25v3.48h5.06a0.95,0.95,0,1,1,0,1.9h-5.06v3.53h6.21a1,1,0,1,1,0,1.9h-7Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M205.8,89.62a5.73,5.73,0,0,1-3.8-1.28,1.53,1.53,0,0,1-.66-1.08,0.93,0.93,0,0,1,.85-0.93,1.33,1.33,0,0,1,.73.32,4.24,4.24,0,0,0,3,1.08c1.63,0,2.68-.65,2.68-1.64,0-.71-0.5-1.21-1.54-1.53l-2.19-.67c-2.18-.66-3.16-1.71-3.16-3.4,0-2.08,1.89-3.59,4.48-3.59,1.65,0,3.64.84,3.64,1.89a0.9,0.9,0,0,1-.87.88,1.16,1.16,0,0,1-.67-0.22A4.18,4.18,0,0,0,206,78.8c-1.37,0-2.41.72-2.41,1.68,0,0.69.63,1.28,1.73,1.61l2.22,0.68c2,0.6,2.93,1.63,2.93,3.33,0,2.11-1.87,3.53-4.66,3.53"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M213,89.61a0.93,0.93,0,0,1-1.1-.95V77.85a0.93,0.93,0,0,1,1.1-.95h7a0.95,0.95,0,1,1,0,1.9h-6.24v3.48h5.06a0.95,0.95,0,1,1,0,1.9h-5.06v3.53H220a1,1,0,1,1,0,1.9h-7Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M231.23,89.62a1,1,0,0,1-.91-0.63l-1-2.45h-4.82L223.6,89a0.94,0.94,0,0,1-1.85-.29,1,1,0,0,1,.07-0.38l4-10.3c0.31-.78.54-1.29,1.07-1.29a1,1,0,0,1,.91.83l0.05,0.14c0,0.12.08,0.22,0.12,0.32l4.14,10.28a0.83,0.83,0,0,1,.09.38,1,1,0,0,1-.93,1m-4.36-9.41c-0.12.37-.23,0.69-0.45,1.26l-1,2.66c-0.09.24-.14,0.38-0.2,0.51h3.37l-1.25-3.18c-0.23-.59-0.35-0.91-0.46-1.25"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M241.77,89.62a1,1,0,0,1-.95-0.66l-2.34-4.72h-3.13v4.38a0.95,0.95,0,1,1-1.9,0V77.85a0.93,0.93,0,0,1,1.09-.95h4.21c2.41,0,3.91,1.43,3.91,3.72a3.21,3.21,0,0,1-2.26,3.33l0.12,0.24,1.86,3.67,0.11,0.25a1.27,1.27,0,0,1,.2.66,0.9,0.9,0,0,1-.93.85m-6.42-7.28h3.39c1.39,0,2-.55,2-1.74s-0.69-1.81-2.11-1.81h-3.31v3.55Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M248.6,89.61a4.57,4.57,0,0,1-4.07-2.18,6.32,6.32,0,0,1-.62-3.21v-2a5.33,5.33,0,0,1,.85-3.32,4.51,4.51,0,0,1,3.92-2,3.92,3.92,0,0,1,3.3,1.48,3.26,3.26,0,0,1,.7,1.82,0.86,0.86,0,0,1-.87.93,0.88,0.88,0,0,1-.84-0.57l-0.29-.62a2.22,2.22,0,0,0-2.13-1.16c-1.85,0-2.74,1.16-2.74,3.54v1.83a5.33,5.33,0,0,0,.43,2.41,2.62,2.62,0,0,0,2.39,1.14,2.33,2.33,0,0,0,2.46-1.59c0.3-.73.47-1.09,1.05-1.09a0.88,0.88,0,0,1,.87.92,4.42,4.42,0,0,1-.82,1.93,4.22,4.22,0,0,1-3.6,1.73"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M262.53,89.61a0.94,0.94,0,0,1-1-1V84.21h-5.31v4.42a1,1,0,1,1-1.9,0V77.88a1,1,0,1,1,1.9,0v4.43h5.31V77.88a0.95,0.95,0,1,1,1.9,0V88.62a0.93,0.93,0,0,1-.95,1"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M183.19,99.09c-2-.62-2.93-1.56-2.93-3.1,0-1.91,1.73-3.27,4.17-3.27,1.6,0,3.32.81,3.32,1.57a0.58,0.58,0,0,1-.55.57,0.84,0.84,0,0,1-.5-0.18,4.52,4.52,0,0,0-2.45-.7c-1.56,0-2.72.86-2.72,2,0,0.84.71,1.54,2,1.91l2.22,0.68c1.91,0.57,2.71,1.47,2.71,3,0,1.95-1.72,3.21-4.34,3.21a5.39,5.39,0,0,1-3.6-1.2,1.21,1.21,0,0,1-.55-0.84,0.62,0.62,0,0,1,.54-0.62,1.06,1.06,0,0,1,.55.26,4.53,4.53,0,0,0,3.14,1.14c1.82,0,3-.78,3-2,0-.86-0.6-1.47-1.77-1.83Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M190.05,97.81c0-3.23,1.55-5.09,4.26-5.09s4.24,1.88,4.24,5.09v1.9c0,3.22-1.55,5.09-4.24,5.09s-4.26-1.86-4.26-5.09v-1.9Zm7.24,0c0-2.48-1.05-3.83-3-3.83s-3,1.35-3,3.83v1.9c0,2.48,1.05,3.82,3,3.82s3-1.34,3-3.82v-1.9Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M201.7,99.65a5.57,5.57,0,0,0,.47,2.56,2.91,2.91,0,0,0,2.67,1.31,2.64,2.64,0,0,0,2.76-1.78c0.31-.76.4-0.89,0.76-0.89a0.57,0.57,0,0,1,.55.6,4.21,4.21,0,0,1-.76,1.75,3.93,3.93,0,0,1-3.34,1.59,4.25,4.25,0,0,1-3.79-2,6.07,6.07,0,0,1-.58-3.06v-2a5,5,0,0,1,.79-3.14,4.21,4.21,0,0,1,3.66-1.85,3.61,3.61,0,0,1,3,1.36,3,3,0,0,1,.63,1.62,0.55,0.55,0,0,1-.55.62,0.56,0.56,0,0,1-.55-0.39l-0.29-.62A2.53,2.53,0,0,0,204.77,94c-2,0-3.06,1.3-3.06,3.86v1.83Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M210.89,93.38a0.63,0.63,0,1,1,1.26,0v10.74a0.63,0.63,0,1,1-1.26,0V93.38Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M216.31,97.74a1.12,1.12,0,0,1,0,.41,1.12,1.12,0,0,1,.41,0h5a0.63,0.63,0,1,1,0,1.26h-5a1.13,1.13,0,0,1-.41,0,1.11,1.11,0,0,1,0,.4v3.45a1.12,1.12,0,0,1,0,.4,1.11,1.11,0,0,1,.41,0h6.17a0.63,0.63,0,1,1,0,1.26h-7a0.63,0.63,0,0,1-.78-0.63V93.35a0.63,0.63,0,0,1,.78-0.63h7a0.63,0.63,0,1,1,0,1.26h-6.2a1.14,1.14,0,0,1-.41,0,1.11,1.11,0,0,1,0,.4v3.4Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M230.09,94a1.1,1.1,0,0,1-.4,0,1.11,1.11,0,0,1,0,.41v9.79a0.63,0.63,0,1,1-1.26,0V94.34a1.12,1.12,0,0,1,0-.41,1.1,1.1,0,0,1-.4,0h-2.63a0.63,0.63,0,1,1,0-1.26h7.28a0.63,0.63,0,1,1,0,1.26h-2.67Z"
      transform="translate(-97.83 -40)"
    />
    <path
      class="cls-2"
      fill="#231f20"
      d="M239.53,104.13a0.63,0.63,0,1,1-1.26,0V99.67l-3.52-6a0.59,0.59,0,0,1-.1-0.36,0.62,0.62,0,0,1,.62-0.62,0.65,0.65,0,0,1,.58.34l2.35,4.05c0.36,0.62.4,0.7,0.66,1.23h0a11.82,11.82,0,0,1,.66-1.23L242,93.06a0.65,0.65,0,0,1,.58-0.34,0.63,0.63,0,0,1,.62.63,0.6,0.6,0,0,1-.11.34l-3.52,5.93v4.51Z"
      transform="translate(-97.83 -40)"
    />
  </svg>
</template>
<script>
export default {
  name: "TheORSociety"
}
</script>