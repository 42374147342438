function mockEmail(snippet, datetimeInMilliseconds, from, subject) {
  return {
    snippet,
    internalDate: datetimeInMilliseconds, // Milliseconds to 20th March 2019
    payload: {
      headers: [
        {
          name: 'To',
          value: 'mrtolgacura@gmail.com'
        },
        {
          name: 'From',
          value: from
        },
        {
          name: 'Subject',
          value: subject
        }
      ],
      body: {
        data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. At elementum eu facilisis sed. Lectus mauris ultrices eros in cursus turpis massa tincidunt. Consequat mauris nunc congue nisi vitae suscipit.'
      }
    }
  }
}

const emails = [
  mockEmail('Gmail Vue free course!', 1553093378790, 'hrishi@learnetto.com', 'Vue JS is so awesome it makes me cry...'),
  mockEmail('Writing dummy data is my new favioute thing after alliteration', 1553093378790, 'test@example.com', 'I also love chocolate'),
  mockEmail('View vue venue venus', 1553093378790, 'test@example.com', 'I am very vocal about my  vlove for valliteration'),
  mockEmail('Frameworks in javascript are like...', 1553093378790, 'test@example.com', 'Crack cocaine, you know its bad but you still make it to earn a living'),
  mockEmail('I love web tech', 1553093378790, 'test@example.com', 'Why isn\' there more frameworks, we need more'),
  mockEmail('Why is react so...', 1553093378790, 'test@example.com', 'Nevermind...'),
];

export default {
  getEmails() {
    // introduce a mocked delay to mimic an API call
    return new Promise(resolve => setTimeout(() => resolve(emails), 1000));
  }
};

