<template>
  <svg
      focusable="false"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 488 170"
    >
      <g fill="none">
        <path
          fill="currentColor"
          d="M124.19 37.94c-3.96-3.78-10.05-3.66-13.59-.11l-73.11 73.11c-.44.44-.83.93-1.16 1.44-1.14 1.8-1.55 3.96-1.25 6.09.09.61.23 1.22.43 1.82.5 1.49 1.36 2.92 2.58 4.13 1.21 1.21 2.64 2.08 4.13 2.58.6.2 1.21.34 1.82.43 2.14.3 4.3-.11 6.09-1.25.51-.33 1-.71 1.44-1.16l73.41-73.41c3.61-3.62 3.28-9.8-.79-13.67zm-13.37-22.59c1.19-1.85 1.65-4.05 1.38-6.22-.08-.62-.21-1.24-.4-1.84-.48-1.51-1.34-2.95-2.55-4.16a10.328 10.328 0 0 0-4.16-2.55 9.75 9.75 0 0 0-1.84-.4c-2.17-.26-4.37.19-6.22 1.38-.53.34-1.03.74-1.49 1.2L70.26 28.04 28 70.3 2.72 95.58c-3.74 3.74-3.51 10.02.56 13.89 3.97 3.78 10.16 3.54 13.83-.12l25.28-25.28 41.95-41.95 25.28-25.28c.46-.46.86-.96 1.2-1.49z"
        />
        <g transform="translate(4 4)">
          <path
            fill="currentColor"
            d="M3.66 55.47c3.96 3.78 10.04 3.67 13.57.14l19.44-19.44c.44-.44.83-.93 1.15-1.44 1.13-1.79 1.54-3.95 1.24-6.08-.09-.61-.23-1.22-.43-1.81-.5-1.49-1.37-2.92-2.58-4.13a10.668 10.668 0 0 0-4.13-2.58c-.6-.2-1.2-.35-1.81-.43-2.13-.3-4.29.11-6.08 1.24-.51.32-.99.71-1.44 1.15L2.85 41.83c-3.6 3.59-3.26 9.76.81 13.64z"
          />
          <circle cx="48.15" cy="10.6" r="10.04" fill="#F80449" />
        </g>
        <circle cx="136.43" cy="26.07" r="10.04" fill="#4F00F2" />
        <g transform="translate(103 102)">
          <path
            fill="currentColor"
            d="M22.38 36.69c3.96 3.78 10.03 3.68 13.55.16l19.38-19.38c.44-.44.82-.92 1.15-1.43 1.13-1.79 1.53-3.94 1.23-6.07-.09-.61-.23-1.22-.43-1.81-.5-1.49-1.37-2.91-2.58-4.13a10.668 10.668 0 0 0-4.13-2.58c-.6-.2-1.2-.35-1.81-.43-2.13-.3-4.28.1-6.07 1.23-.51.32-.99.71-1.43 1.15L21.56 23.08c-3.6 3.58-3.25 9.73.82 13.61z"
          />
          <circle cx="10.09" cy="48.61" r="10.04" fill="#09CFBB" />
        </g>
        <circle cx="25.93" cy="136.57" r="10.04" fill="#4F00F2" />
        <path
          fill="currentColor"
          d="M163.02 67.3c1.19-1.85 1.65-4.05 1.38-6.22-.08-.62-.21-1.24-.4-1.84-.48-1.51-1.34-2.95-2.55-4.16a10.328 10.328 0 0 0-4.16-2.55 9.75 9.75 0 0 0-1.84-.4c-2.17-.27-4.37.19-6.22 1.38-.53.34-1.03.74-1.49 1.2l-25.53 25.53-42.01 42.01-25.53 25.53c-3.74 3.74-3.51 10.02.56 13.89 3.96 3.78 10.16 3.54 13.83-.12l25.53-25.53 41.7-41.7 25.53-25.53c.46-.46.86-.96 1.2-1.49zM457 62.08h27.46v82.37H457z"
        />
        <circle cx="252.35" cy="34.62" r="16.5" fill="currentColor" />
        <path
          fill="currentColor"
          d="M311.12 77.35c5.66-11.67 14.59-16.82 30.2-18.02v30.2c-4.8-1.54-9.78-1.54-15.1 0-8.41 2.23-15.1 11.67-15.1 24.54v30.38h-27.46V62.08h27.46v15.27zm118.6-8.06c6.35 6.69 9.44 16.3 9.44 28.49v46.68H411.7v-43.93c0-10.98-4.63-16.47-13.73-16.47-6.86 0-13.73 6.35-13.73 19.22v41.19h-27.46V62.1h27.46v8.24c4.8-7.38 12.18-10.98 21.97-10.98 9.27-.03 17.16 3.23 23.51 9.93z"
        />
        <circle cx="470.73" cy="34.62" r="16.5" fill="currentColor" />
        <path
          fill="currentColor"
          d="M238.62 62.08h27.46v38.93c0 30.72-15.44 45.31-42.73 45.31-8.92 0-20.77-4.29-25.57-8.41l13.73-20.59c2.57 2.23 7.73 4.29 10.99 4.29 10.81 0 16.13-6.52 16.13-19.56V62.08h-.01z"
        />
      </g>
    </svg>
</template>
<script>
export default {
  name: 'Jrni'
}
</script>