import Maps from './maps';

const HEATMAP = 'heatmap';
const CLUSTER = 'cluster';

class GoogleMaps extends Maps {
  constructor() {
    super();
    this.map = null;
    this.visualLayer = null;
    this.visualLayerType = CLUSTER;
  }

  getStylesheetsToLoad() {
    return [];
  }

  getScriptsToLoad() {
    return [
      'https://maps.googleapis.com/maps/api/js?libraries=visualization',
      'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/markerclusterer.js',
    ];
  }

  generateMap({ latitude, longitude, zoom }) {
    this.map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: latitude, lng: longitude },
      zoom: zoom,
      fullscreenControl: false,
    });
  }

  parseDataPoints(dataPoints) {
    switch (this.visualLayerType) {
      case HEATMAP:
        return this.parseHeatmapDataPoints(dataPoints);
      case CLUSTER:
      default:
        return this.parseClusterDataPoints(dataPoints);
    }
  }

  parseHeatmapDataPoints(dataPoints) {
    return dataPoints.map((dataPoint) => {
      return new window.google.maps.LatLng(dataPoint.location.latitude, dataPoint.location.longitude)
    });
  }

  parseClusterDataPoints(dataPoints) {
    return dataPoints.map((dataPoint) => {
      const position = new window.google.maps.LatLng(dataPoint.location.latitude, dataPoint.location.longitude)

      return new window.google.maps.Marker({
        position,
      });
    })
  }

  loadVisualLayer(parsedDataPoints) {
    switch (this.visualLayerType) {
      case HEATMAP:
        this.loadHeatMapVisualLayer(parsedDataPoints);
        break;
      case CLUSTER:
      default:
        this.loadClusterVisualLayer(parsedDataPoints);
    }
  }

  loadHeatMapVisualLayer(parsedDataPoints) {
    this.visualLayer = new window.google.maps.visualization.HeatmapLayer({
      data: parsedDataPoints,
      map: this.map,
      radius: 20,
    });
  }

  loadClusterVisualLayer(parsedDataPoints) {
    this.visualLayer = new window.MarkerClusterer(this.map, parsedDataPoints, { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
  }

  setZoom(zoom) {
    this.map.setZoom(zoom);
  }

  setCenter({ latitude, longitude }) {
    this.map.setCenter({ lat: latitude, lng: longitude });
  }
}

const googleMaps = new GoogleMaps();

export default googleMaps;