<template>
  <div id="app">
    <div id="header">
      <div id="header__left">
        <div id="menu-btn">
          <i class="fas fa-bars"></i>
        </div>
        <img id="logo" src="./assets/logo.png" />
        <span id="app-name">Vuemail</span>
      </div>
      <div id="header__right">
        <div id="search-bar">
          <i class="fas fa-search"></i>
          <span id="search-text">Search mail</span>
        </div>
      </div>
    </div>

    <div id="emails">
      <div id="sidebar">
        <div class="sidebar__item sidebar__item--selected">
          <i class="fas fa-inbox"></i>Inbox
          <span class="email-count">{{emails.length}}</span>
        </div>
        <div class="sidebar__item">
          <i class="fas fa-star"></i>Starred
        </div>
        <div class="sidebar__item">
          <i class="fas fa-clock"></i>Snoozed
        </div>
        <div class="sidebar__item">
          <i class="fas fa-chevron-right"></i>Important
        </div>
        <div class="sidebar__item">
          <i class="fas fa-paper-plane"></i>Sent
        </div>
        <div class="sidebar__item">
          <i class="fas fa-sticky-note"></i>Drafts
        </div>
      </div>
      <table id="email-list">
        <EmailSummary v-bind:key="index" v-for="(email, index) in emails" :email="email" />
      </table>
    </div>
  </div>
</template>

<script>
import Gmail from "./services/gmail";
import EmailSummary from "./components/EmailSummary";

export default {
  name: "app",
  components: {
    EmailSummary
  },
  data() {
    return {
      emails: []
    };
  },
  mounted() {
    Gmail.getEmails().then(emails => (this.emails = emails));
  }
};
</script>

<style>
#emails {
  display: flex;
  flex-direction: row;
}

#header {
  padding: 5px;
  border-bottom: 1px solid rgb(225, 225, 225);
  display: flex;
  flex-direction: row;
}

#header__left {
  flex: 1;
  padding: 10px 0;
}

#header__right {
  flex: 6;
  display: flex;
  align-items: center;
}

#menu-btn {
  border: none;
  padding: 10px 15px;
  font-size: 20px;
  display: inline-block;
}

#menu-btn:hover {
  background-color: #efefef;
  border-radius: 30px;
}

#sidebar {
  flex: 1;
}

.sidebar__item {
  padding: 10px;
  padding-left: 30px;
}

.sidebar__item .fas,
#header .fas {
  color: #797979;
}

.sidebar__item .fas {
  margin-right: 20px;
}

.sidebar__item:hover {
  background-color: #efefef;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  cursor: pointer;
}

.sidebar__item--selected {
  color: red;
  background-color: #fce8e6;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.sidebar__item--selected:hover {
  background-color: #fce8e6;
}

.sidebar__item--selected .fas {
  color: red;
}

.email-count {
  color: red;
  float: right;
  margin-right: 10px;
}

#email-list {
  width: 100%;
  flex: 6;
}

#logo {
  width: 30px;
  position: relative;
  top: 8px;
  left: 5px;
  padding: 0;
}

#app-name {
  font-size: 20px;
  margin-left: 10px;
  color: #4c4b4b;
}

#search-bar {
  background-color: #f2f3f4;
  width: 500px;
  padding: 20px;
  border-radius: 10px;
}

#search-text {
  margin-left: 20px;
  color: #797979;
  font-size: 16px;
}
</style>
