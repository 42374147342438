<template>
  <div id="main-message">
    <div>Oops!</div>
    <div>Page not found</div>
    <img id="image" src="../assets/sad-dog.jpeg">
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
#main-message {
  font-size: 80px;
  text-align: center;
  font-weight: bold;
  margin-top: 70px;
}

#image {
  border: 5px solid black;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
</style>