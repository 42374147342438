<template>
  <div class="relative w-full">
    <div id="text" :style="{ 'animation-delay': delay }">
      <slot></slot>
    </div>

    <div
      id="cover"
      class="absolute"
      :style="{ 'background-color': color, 'animation-delay': delay }"
    ></div>
  </div>
</template>
<script>
export default {
  name: "Uncover",
  props: {
    color: String,
    delay: String,
  },
};
</script>
<style scoped>
@keyframes uncover {
  0% {
    left: 0;
    right: 100%;
  }

  50% {
    left: 0;
    right: 0;
  }

  100% {
    left: 100%;
    right: 0;
  }
}

@keyframes text {
  50% {
    opacity: 0;
  }

  51% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

#text {
  opacity: 0;
  animation: 1.5s text forwards;
}

#cover {
  top: 0;
  bottom: 0;
  animation: 1.5s uncover ease-in-out;
}
</style>