<template>
  <div id="image-container" class="my-auto">
    <div class="relative md:w-80 animate__animated animate__fadeIn animate__delay-1s">
      <img
        id="image"
        class="width-full z-10 relative"
        src="../assets/me_outside.jpg"
      />
      <div
        id="border"
        class="absolute border-8 border-white z-0 animate__animated animate__fadeIn"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProfileImage",
};
</script>
<style scoped>
@keyframes shiftTopLeft {
  0% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  100% {
    top: -16px;
    left: -16px;
    right: 16px;
    bottom: 16px;
  }
}

@keyframes shiftBottomRight {
  0% {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  100% {
    top: 16px;
    left: 16px;
    right: -16px;
    bottom: -16px;
  }
}

#image {
  animation: 0.7s ease-in-out 2s forwards shiftTopLeft;
}

#border {
  animation: 0.7s ease-in-out 2s forwards shiftBottomRight;
  border-width: 14px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#image-container {
  max-width: calc(100vw - 20px - 32px)
}
/*
@media (max-width: 640px) {
  #image-container > div {
    width: unset;
  }
} */
</style>