import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import NotFound from '../views/NotFound.vue'
import tutorial from '../views/tutorials/passport-js-on-different-server/Index.vue';
import GmailInVue from '../views/tutorials/gmail/Index.vue';
import mapProject from '../views/projects/map/Index.vue';

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Index },
  { path: '/tutorial', component: tutorial },
  { path: '/tutorials/vuemail', component: GmailInVue, meta: { title: 'Vuemail - Tolga Cura' } },
  { path: '/projects/map', component: mapProject },
  { path: '/404', component: NotFound },
  { path: '*', redirect: '/404' },
]

const router = new VueRouter({
  routes
})

export default router
