<template>
  <div id="boris-map">
    <!-- <Timeline id="timeline" v-bind:candidate="selectedCandidate"/> -->
    <div id="map-container">
      <Map v-bind:dataPoints="dataPoints" v-bind:getCurrentLocationCallback="getCrimesInLocation"/>
    </div>

    <!-- <SearchBox/> -->

    <!-- <CandidateSelector id="canidate-selector" v-bind:selectCandidateCallback="selectCandidate"/> -->

  </div>
</template>
<script>
import Map from "./components/Map";
// import SearchBox from "./components/SearchBox";
// import Timeline from "./components/Timeline";
// import CandidateSelector from "./components/CandidateSelector";

import Police from "./services/police";

export default {
  components: {
    Map,
    // SearchBox,
    // Timeline,
    // CandidateSelector
  },
  computed: {
    selectedCandidate() {
      return this.selectedCandidateRaw;
    },
    dataPoints() {
      return this.crimeDataPoints;
    }
  },
  methods: {
    selectCandidate(candidate) {
      this.selectedCandidateRaw = candidate;
    },
    getCrimesInLocation(position) {
      const { latitude, longitude } = position.coords;

      Police.getCrimesAtLocation({ latitude, longitude }).then((crimes) => {
        this.crimeDataPoints = crimes;
      })
    }
  },
  data() {
    return {
      selectedCandidateRaw: {},
      crimeDataPoints: [],
    }
  }
};
</script>
<style scoped>
#boris-map {
  display: flex;
}

#boris {
  height: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
}

#map-container {
  height: 100vh;
  flex: 5;
}

#timeline {
  flex: 2;
}

#canidate-selector {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>




